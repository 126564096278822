import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import _ from '@lodash';
import InputAdornment from '@mui/material/InputAdornment';
import { Controller, useFormContext } from 'react-hook-form';
import AuraFreeSoloControlledAutocomplete from 'app/main/apps/common/AuraFreeSoloControlledAutocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import { getFile } from 'app/main/apps/e-commerce/store/filesSlice';
import { calculateWeight } from 'app/main/apps/common/AuraFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { getOrderItemDiscount, getDefaultWeight } from '../../store/orderSlice';
import { convertToQuoteShippingProfile, getCustomerMargin } from '../../store/rfqSlice';

function CustomProductFormContent(props) {
    const dispatch = useDispatch();
    const methods = useFormContext();
    const [weightCalculation, setWeightCalculation] = useState(false)
    const [weightAdjustment, setWeightAdjustment] = useState('')
    const { control, formState, watch, setValue } = methods;
    const { errors } = formState;
    const productColors = useSelector(({ eCommerceApp }) => eCommerceApp.products.productColors);
    const productTypes = useSelector(({ eCommerceApp }) => eCommerceApp.products.productTypes);
    const productSubTypes = useSelector(({ eCommerceApp }) => eCommerceApp.products.productSubTypes);
    const productPolishes = useSelector(({ eCommerceApp }) => eCommerceApp.products.productPolishes);
    const currentUser = useSelector(({ user }) => user);
    const isCustomer = currentUser && currentUser.role === "customer";
    const stoneColor = watch('stone_color');
    const type = watch('type');
    const length = watch('length');
    const width = watch('width');
    const height = watch('height');
    const polish = watch('polish');
    const fob = watch('unit_cost');
    const weight = watch('weight');
    const quantity = watch('order_quantity');
    const margin = watch('margin')
    const shipping = watch('shipping')
    const discount = watch('discount')
    const tariff = watch('tariff')
    const { mode, isReadOnly } = props;
    const showDiscount = props?.stage !== "rfq"  || (props?.stage === "rfq" && props?.orderType === "convert-to-quote");

    const getPolish = () => {
        if (polish) {
            if (typeof polish === 'object') {
                return polish.inputValue || polish.name
            }
            return polish
        }
        return ''
    }

    useEffect(() => {
        if (props.isManage || isCustomer) {
            if (length > 0 && width > 0 && height > 0 && stoneColor && type?.name) {
                const stoneColorName = stoneColor?.inputValue ?? stoneColor.name
                const typeName = type?.inputValue ?? type.name
                setValue('name', `${stoneColorName} ${typeName} ${length}x${width}x${height} ${getPolish()} `, { shouldValidate: true });
                setValue(
                    'description',
                    `${stoneColorName} ${typeName} ${length}x${width}x${height} ${getPolish()} `,
                    { shouldValidate: true }
                );
            }
        }
    }, [length, width, height, polish, stoneColor, type, setValue, props.isManage]);

    useEffect(() => {
        const typeName = type?.inputValue ?? type?.name
        if (['Bevel', 'Slant'].includes(typeName)) {
            dispatch(getDefaultWeight()).then((action) => {
                setWeightAdjustment(action.payload['weight adjustment'])
            })
        } else {
            setWeightAdjustment('')
        }
    }, [type])

    useEffect(() => {
        const tempPrice = calculatePrice()
        const unitPrice = tempPrice > 0 ? tempPrice : ''
        setValue('unit_price', unitPrice)
    }, [fob, margin, tariff, shipping, discount, weight, quantity])

    useEffect(() => {
        if (length && height && width) {
            const baseWeight = calculateWeight(true, length, width, height);
            const calWeight = _.round(weightAdjustment?.length > 0
                ? baseWeight * (parseInt(weightAdjustment, 10) / 100)
                : baseWeight);
            if (mode === 'edit_custom_product') {
                setWeightCalculation(true)
            } else {
                setValue('weight', calWeight)
            }

            if (weightCalculation) {
                setValue('weight', calWeight)
            }
        }
    }, [length, height, width, mode, weightAdjustment])

    useEffect(() => {
        if (mode !== 'edit_custom_product' && !props.customerId) {
            dispatch(convertToQuoteShippingProfile({ params: { category: "default_shipping_profile" } })).then(({ payload }) => {
                setValue('tariff', payload.tariff)
                setValue('margin', payload.margin)
                setValue('shipping', payload.shipping)
            })
        }
        if ((mode !== 'edit_custom_product' && props.customerId) || isCustomer) {
            dispatch(getCustomerMargin(props.customerId)).then(({ payload }) => {
                setValue('tariff', payload.tariff)
                setValue('margin', payload.margin)
                setValue('shipping', payload.shipping)
            })
            const customerId = isCustomer ? currentUser?.customer?.id : props.customerId
            if(showDiscount === true) {
                dispatch(getOrderItemDiscount({ cid: customerId, stage: props.stage })).then(
                    (action) => {
                        const discountValue =
                            action.payload && action.payload.discount ? action.payload.discount : '';
                        setValue('discount', `${discountValue}`);
                    }
                );
            }
        }
    }, [mode, dispatch])


    function onStoneDataUpdate(event, value) {
        if (value && value.id && value.attachment_id) {
            dispatch(getFile(value.attachment_id)).then((response) => {
                const responseData = response ? response.payload : null;
                if (responseData) {
                    const stoneColorData = { ...value, attachment: responseData.data.file };
                    setValue('stone_color', stoneColorData);
                }
            });
        }
    }

    function stoneValidate() {
        return !!(stoneColor && !stoneColor.name && stoneColor.attachment && stoneColor.attachment.id);
    }

    const calculatePrice = (isRoundPrice) => {
        const shipVal = Number(weight) * Number(shipping); // Logic added back
        const tariffVal = Number(fob) * (Number(tariff) / 100);
        const value = (Number(fob) + shipVal + tariffVal) / (1 - Number(margin / 100));
        const priceVal = value && Number(value) ? value.toFixed(0) : 0;
        const wholeSalePrice = Number(priceVal)
        return isRoundPrice ? wholeSalePrice : Math.round(wholeSalePrice)
    }

    const handlePercentageValue = (event, field) => {
        const { value } = event.target;

        // Allow empty values, and ensure the value is a valid number or decimal between 0 and 99.99
        if (value === '' || (/^(\d{1,2}(\.\d{0,2})?)$/.test(value) && Number(value) <= 99.99)) {
            field.onChange(value);
        }
    };

    useEffect(() => {
        const handleWheel = (e) => {
          if (e.target.type === "number") {
            e.target.blur();
          }
        };
    
        document.addEventListener("wheel", handleWheel, { passive: true });
    
        return () => {
          document.removeEventListener("wheel", handleWheel);
        };
      }, []);
    

    return (
        <div>
            <div className="flex flex-col md:flex-row w-full">
                <div className="flex w-full">
                    <div className="flex flex-col w-full">
                        <div className="flex flex-col md:flex-row w-full">
                            <div className="flex w-full md:w-4/7 md:ml-16">
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <div className="flex gap-10 mt-8 mb-16">
                                            <Typography variant="body1" className="font-bold">
                                                Name
                                            </Typography>
                                            <Typography variant="body1" color={fieldState.error ? 'error' : 'textSecondary'}>
                                                {fieldState.error ? fieldState.error.message : field.value}
                                            </Typography>
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex w-full md:w-4/7 md:ml-2">
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="flex gap-10 mt-8 mb-16">
                                            <Typography variant="body1" className="font-bold">
                                                Description
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                {field.value}
                                            </Typography>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>

                        {/* First Row */}
                        {
                            props.isCustomProduct && (
                                <div className="flex">
                                    <div className="flex flex-col md:flex-row w-full">

                                        <div className="flex w-full md:w-2/6">
                                            <AuraFreeSoloControlledAutocomplete
                                                control={control}
                                                controlName="stone_color"
                                                inputValues={productColors}
                                                labelName="Stone Color *"
                                                readOnly={isReadOnly}
                                                onUpdate={onStoneDataUpdate}
                                                textClassName="mt-8 mb-0"
                                            />
                                            {stoneValidate() && <FormHelperText error>Select stone color</FormHelperText>}
                                        </div>

                                        <div className="flex w-full md:w-2/6 md:ml-16">
                                            <Controller
                                                name="length"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="mt-8 mb-16"
                                                        id="length"
                                                        label="Length"
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        type="number"
                                                        InputProps={{
                                                            readOnly: isReadOnly,
                                                        }}
                                                        required={(props.isManage && !isReadOnly) || isCustomer}
                                                        error={!!errors.length}
                                                        helperText={errors?.length?.message}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="flex w-full md:w-2/6 md:ml-16">
                                            <Controller
                                                name="width"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="mt-8 mb-16"
                                                        id="width"
                                                        label="Width"
                                                        variant="outlined"
                                                        type="number"
                                                        fullWidth
                                                        size="small"
                                                        required={(props.isManage && !isReadOnly) || isCustomer}
                                                        error={!!errors.width}
                                                        helperText={errors?.width?.message}
                                                        InputProps={{
                                                            readOnly: isReadOnly,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="flex w-full md:w-2/6 md:ml-16">
                                            <Controller
                                                name="height"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="mt-8 mb-16"
                                                        id="height"
                                                        label="Height"
                                                        variant="outlined"
                                                        type="number"
                                                        fullWidth
                                                        size="small"
                                                        InputProps={{
                                                            readOnly: isReadOnly,
                                                        }}
                                                        required={(props.isManage && !isReadOnly) || isCustomer}
                                                        error={!!errors.height}
                                                        helperText={errors?.height?.message}
                                                    />
                                                )}
                                            />
                                        </div>

                                    </div>
                                </div>
                            )
                        }
                        {console.log("productTypes", productTypes)}
                        {console.log("productSubTypes", productSubTypes)}

                        {/* Second Row */}
                        <div className="flex">
                            <div className="flex flex-col md:flex-row w-full">
                                {
                                    props.isCustomProduct && (
                                        <>
                                            <div className="flex w-full md:w-2/6">
                                                <AuraFreeSoloControlledAutocomplete
                                                    control={control}
                                                    controlName="type"
                                                    inputValues={productTypes || []}
                                                    labelName="Type *"
                                                    readOnly={isReadOnly}
                                                    onUpdate={() => {
                                                        setValue('sub_type', '')
                                                        setValue('polish', '')
                                                    }}
                                                />
                                            </div>

                                            <div className="flex w-full md:w-2/6 md:ml-16">
                                                <AuraFreeSoloControlledAutocomplete
                                                    control={control}
                                                    controlName="sub_type"
                                                    inputValues={productSubTypes || []}
                                                    labelName="Sub Type"
                                                    readOnly={isReadOnly}
                                                />
                                            </div>
                                            <div className="flex w-full md:w-2/6 md:ml-16">
                                                <AuraFreeSoloControlledAutocomplete
                                                    control={control}
                                                    controlName="polish"
                                                    inputValues={productPolishes || []}
                                                    labelName="Polish *"
                                                    readOnly={isReadOnly}
                                                    textClassName="mt-8 mb-0"
                                                />
                                            </div>

                                            <div className="flex w-full md:w-2/6 md:ml-16">
                                                <Controller
                                                    name="order_quantity"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            className="mt-8 mb-16"
                                                            id="order_quantity"
                                                            error={!!errors.order_quantity}
                                                            required={(props.isManage && !isReadOnly) || isCustomer}
                                                            helperText={errors?.order_quantity?.message}
                                                            label="Order Quantity"
                                                            type="number"
                                                            variant="outlined"
                                                            fullWidth
                                                            size="small"
                                                            InputProps={{
                                                                readOnly: isReadOnly,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    !props.isCustomProduct && (
                                        <div className="flex w-full md:w-2/6 md:ml-16">
                                            <Controller
                                                name="order_quantity"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="mt-8 mb-16"
                                                        id="order_quantity"
                                                        error={!!errors.order_quantity}
                                                        required={(props.isManage && !isReadOnly) || isCustomer}
                                                        helperText={errors?.order_quantity?.message}
                                                        label="Order Quantity"
                                                        type="number"
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        InputProps={{
                                                            readOnly: isReadOnly,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    )
                                }

                            </div>
                        </div>

                        {/* Third Row */}

                        <div className="flex">
                            <div className="flex flex-col md:flex-row w-full">
                                <div className={`flex w-full md:w-2/6 `}>
                                    <Controller
                                        name="customer_provider_number"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                className="mt-8 mb-16"
                                                id="customer_provider_number"
                                                label="Reference No"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                InputProps={{
                                                    readOnly: isReadOnly,
                                                }}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="flex w-full md:w-2/6 md:ml-16">
                                    <Controller
                                        name="decedent"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                className="mt-8 mb-16"
                                                id="decedent"
                                                label="Decedent"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                InputProps={{
                                                    readOnly: isReadOnly,
                                                }}
                                            />
                                        )}
                                    />
                                </div>

                                {props.isCustomProduct && <>
                                    <div className="flex w-full md:w-2/6 md:ml-16">
                                        {!isCustomer && <Controller
                                            name="weight"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    className="mt-8 mb-16"
                                                    id="weight"
                                                    error={!!errors.weight}
                                                    helperText={errors?.weight?.message}
                                                    label="Weight"
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    type="number"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">lbs</InputAdornment>
                                                        ),
                                                        readOnly: isReadOnly,

                                                    }}
                                                    required
                                                />
                                            )}
                                        />}
                                    </div>
                                    {
                                        props.hideAdditionalFields && showDiscount === true && (<>
                                            <div className='flex w-full md:w-2/6 md:ml-16'>
                                                {!isCustomer && <Controller
                                                    name="discount"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            className="mt-8 mb-16"
                                                            id="discount"
                                                            label="Discount"
                                                            variant="outlined"
                                                            fullWidth
                                                            size="small"
                                                            type="number"
                                                            onChange={(event) => handlePercentageValue(event, field)}
                                                            InputLabelProps={{ shrink: true }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="start">%</InputAdornment>
                                                                ),
                                                                readOnly: isReadOnly || isCustomer,
                                                            }}
                                                            disabled={isCustomer}
                                                        />
                                                    )}
                                                />}
                                            </div>
                                        </>)
                                    }

                                    {!props.hideAdditionalFields && showDiscount === true && (<>

                                        <div className='flex w-full md:w-2/6 md:ml-16'>
                                            <Controller
                                                name="discount"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="mt-8 mb-16"
                                                        id="discount"
                                                        label="Discount"
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        type="number"
                                                        onChange={(event) => handlePercentageValue(event, field)}
                                                        InputLabelProps={{ shrink: true }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="start">%</InputAdornment>
                                                            ),
                                                            readOnly: isReadOnly,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </>)}

                                </>}
                            </div>
                        </div>



                        {/* Fourth Row */}
                        {
                            props.isCustomProduct && (
                                <>
                                    <div className="flex">
                                        <div className="flex flex-col md:flex-row w-full">

                                            {!props.hideAdditionalFields && (<>
                                                <div className="flex w-full md:w-2/6">
                                                    <Controller
                                                        name="unit_cost"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                className="mt-8 mb-16"
                                                                id="unit_cost"
                                                                label="FOB"
                                                                variant="outlined"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                error={!!errors.unit_cost}
                                                                helperText={errors?.unit_cost?.message}
                                                                required={props.stage === 'quote' || props.orderType === 'convert-to-quote'}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="start">$</InputAdornment>
                                                                    ),
                                                                    readOnly: isReadOnly,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="flex w-full md:w-2/6 md:ml-16">
                                                    <Controller
                                                        name="tariff"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                className="mt-8 mb-16"
                                                                id="tariff"
                                                                label="Tariff"
                                                                variant="outlined"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="start">%</InputAdornment>
                                                                    ),
                                                                    readOnly: isReadOnly,

                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="flex w-full md:w-2/6  md:ml-16">
                                                    <Controller
                                                        name="shipping"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                className="mt-8 mb-16"
                                                                id="shipping"
                                                                label="Shipping"
                                                                variant="outlined"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="start">$</InputAdornment>
                                                                    ),
                                                                    readOnly: isReadOnly,

                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="flex w-full md:w-2/6 md:ml-16">
                                                    <Controller
                                                        name="margin"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                onChange={(event) => handlePercentageValue(event, field)}
                                                                className="mt-8 mb-16"
                                                                id="margin"
                                                                label="Margin"
                                                                variant="outlined"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                InputProps={{
                                                                    maxLength: 2,
                                                                    endAdornment: (
                                                                        <InputAdornment position="start">%</InputAdornment>
                                                                    ),
                                                                    readOnly: isReadOnly,

                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </>)}
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        {/* Fifth Row */}

                        {
                            props.isCustomProduct && (
                                <>
                                    <div className="flex">
                                        <div className="flex flex-col md:flex-row w-full">
                                            {!props.hideAdditionalFields && (<>
                                                <div className="flex w-full" style={{ width: '24%' }}>
                                                    <Controller
                                                        name="unit_price"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                className="mt-8 mb-16"
                                                                id="unit_price"
                                                                label="Unit Price"
                                                                variant="outlined"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="start">$</InputAdornment>
                                                                    ),
                                                                    readOnly: true,

                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </>)}
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
                {/* <div className="flex justify-center md:w-1/5">
                    {!isReadOnly && (
                        <Controller
                            name="stone_image"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <label
                                    htmlFor="button-file"
                                    className="productImageUpload flex items-center justify-center relative w-128 h-128 rounded-16 mx-12 mb-12 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                                >
                                    <input
                                        accept="image/*"
                                        className="hidden"
                                        id="button-file"
                                        type="file"
                                        onChange={async (e) => {
                                            function readColorFileAsync() {
                                                return new Promise((resolve, reject) => {
                                                    const file = e.target.files[0];
                                                    if (!file) {
                                                        return;
                                                    }
                                                    dispatch(uploadFile({ file, addTo: 'Product' })).then((response) => {
                                                        const reader = new FileReader();
                                                        reader.onload = () => {
                                                            resolve({
                                                                id: response.payload.id,
                                                                content_type: file.type,
                                                                data: btoa(reader.result),
                                                            });
                                                        };
                                                        reader.onerror = reject;
                                                        reader.readAsBinaryString(file);
                                                    });
                                                });
                                            }
                                            const newImage = await readColorFileAsync();
                                            onChange({ ...newImage, ...value });
                                            const tempDta = {
                                                content_type: newImage.content_type,
                                                data: newImage.data,
                                                id: newImage.id,
                                            };
                                            const stoneColorData = { ...stoneColor };
                                            stoneColorData.attachment = tempDta;
                                            setValue('stone_color', stoneColorData);
                                        }}
                                    />
                                    {stoneColor && stoneColor.attachment && (
                                        <div className="productImageItem flex items-center justify-center relative w-128 h-128  featured rounded-16 overflow-hidden cursor-pointer outline-none ">
                                            <img
                                                src={`data:${ stoneColor.attachment.content_type }; base64, ${ stoneColor.attachment.data } `}
                                                className="max-w-none w-auto h-full"

                                                alt=''
                                            />
                                        </div>
                                    )}
                                    {(!stoneColor || (stoneColor && !stoneColor.attachment)) && (
                                        <Icon fontSize="large" color="action">
                                            cloud_upload
                                        </Icon>
                                    )}
                                    {stoneColor && stoneColor.attachment && (
                                        <IconButton
                                            className="productImageFeaturedStar absolute top-0 right-0"
                                            onClick={(ev) => {
                                                clearImage(ev)
                                            }}
                                            size="small"
                                        >
                                            <Icon>clear</Icon>
                                        </IconButton>
                                    )}
                                </label>
                            )}
                        />
                    )}
                    {isReadOnly && stoneColor && stoneColor.attachment && (
                        <div className="productImageItem flex items-center justify-center relative w-128 h-128  featured rounded-16 overflow-hidden cursor-pointer outline-none ">
                            <img
                                src={`data:${ stoneColor.attachment.content_type }; base64, ${ stoneColor.attachment.data } `}
                                className="max-w-none w-auto h-full"
                                alt=""
                            />
                        </div>
                    )}
                </div> */}

            </div>

            <div className="flex flex-col md:flex-row w-full">
                <Controller
                    name="comments"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            className="mt-8 mb-16"
                            id="note"
                            label="Comments"
                            type="text"
                            multiline
                            rows={5}
                            variant="outlined"
                            fullWidth
                            size="small"
                            InputProps={{
                                readOnly: isReadOnly,
                            }}
                        />
                    )}
                />
            </div>

        </div>
    );
}

export default CustomProductFormContent;