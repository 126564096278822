
import clsx from 'clsx';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import DialogContentText from '@mui/material/DialogContentText';
import { DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Confirm from '@fuse/core/FuseDialog/confirm';
import Icon from '@mui/material/Icon';
import { useState, useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOrders, getOrders, sendInvoice } from 'app/main/apps/e-commerce/store/ordersSlice';
import dispatchMessage from 'app/main/apps/common/AuraFunctions';
import { showMessage } from "app/store/fuse/messageSlice";
import { getEcomWidgetsOrderItems } from 'app/main/dashboards/main/store/widgetsSlice';
import { capitalize } from 'lodash';
import { openOrderDialog, performAction } from '../store/orderSlice';


const useToolbarStyles = makeStyles((theme) => ({
  root: {
    minHeight: '45px',
    paddingLeft: '0px',
    color: theme.palette.text.primary,
  },
  colorBox: {},
}));

function OrdersToolBar(props) {
  const dispatch = useDispatch();
  const classes = useToolbarStyles();
  const { toggleAllRowsSelected, selectedRowIds, selectedFlatRows, toolbarParam } = props;
  const { options, stage } = toolbarParam
  const numSelected = Object.keys(selectedRowIds).length;
  const actionType = ['customer_archive', 'supplier_archive', 'archive_rfq', 'archive_quote'].includes(options?.value)
  const location = window.location.search
  const orderId = selectedFlatRows?.map(item => item.original.id)
  const currentUser = useSelector(({ user }) => user);
  const { role } = currentUser
  const [loading, setLoading] = useState(false)

  function sendOrderInvoice() {
    const selectedRows = selectedFlatRows.map((d) => d.original);
    const data = selectedRows.map((d) => d.id);
    dispatch(sendInvoice({ oids: data })).then((response) => {
      if (response.error) {
        dispatchMessage(dispatch, 'Failed to send Invoice', 'error');
        toggleAllRowsSelected(false);
      } else {
        dispatchMessage(dispatch, 'Invoice sent successfully');
        toggleAllRowsSelected(false);
      }
    });
  }
  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 1301, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {numSelected && numSelected > 0 ? (
    <Toolbar className={clsx(classes.root, 'flex items-start')}>
      <div className="flex flex-row items-center">
        <Box className="flex items-center font-medium font-bold">{numSelected}</Box>
        <Typography className="pl-8 font-medium" color="inherit">
          {toolbarParam.selectionMsg ? toolbarParam.selectionMsg : 'Row(s) Selected'}
        </Typography>
      </div>
      <div className="flex flex-row items-center">
        {/* This is commented as invoice genration done automatically  */}
        {/* <Button
          aria-label="Send Invoice"
          color="secondary"
          size="small"
          variant="contained"
          onClick={sendOrderInvoice}
          sx={{ padding: '0px 15px', marginLeft: '10px' }}
        >
          Send Invoice
        </Button> */}
      </div>
      <Button
        color="secondary"
        size="small"
        variant="contained"
        sx={{ padding: '0px 15px', marginLeft: '10px' }}
        onClick={(ev) => {
          ev.stopPropagation();
          dispatch(
            openDialog({
              children: (
                <Confirm title={actionType ? 'Unarchive' : 'Archive'}
                  content={`Are you sure you want to permanently  ${actionType ? "unarchive the selected item?" : "archive the selected item?"}`}
                  onSubmit={() => {
                    dispatch(performAction({
                      ui_id: "ARCHIVE",
                      data: {
                        order_ids: orderId,
                        is_archive: !(['customer_archive', 'supplier_archive', 'archive_rfq', 'archive_quote'].includes(options?.value))
                      },
                    })).then(res => {
                      if (!res.error) {
                        dispatch(showMessage({ message: `Selected items have been  ${actionType ? 'restored' : ' moved to archive'}`, type: 'success' }))
                        dispatch(getOrders({ params: { stage }, goToFirstPage: true }))
                      }
                    })
                  }}
                />
              ),
            })
          );
        }}
      >
        {actionType ? 'Unarchive' : 'Archive'}
      </Button>
      {stage === 'order' && !location.includes('&archive=true') && <Button
        color="secondary"
        size="small"
        variant="contained"
        sx={{ padding: '0px 15px', marginLeft: '10px' }}
        onClick={() => {
          dispatch(
            openOrderDialog({
              data: {
                order_ids: orderId
              },
              param: {
                name: 'discount',
                title: 'Update Discount',
                successMessage: 'Your selected order(s) have been updated with a new discount!',
                submitButtonName: "Update",
                loadOrdersFun: () => dispatch(getOrders({ params: { stage }, goToFirstPage: true }))
              },
            })


          );
        }}
      >
        Update Discount
      </Button>
      }
      {
        ['order', 'quote', 'rfq'].includes(stage) && role === 'admin' && (
          <Button
            color="secondary"
            size="small"
            variant="contained"
            sx={{ padding: '0px 15px', marginLeft: '10px' }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                openDialog({
                  children: (
                    <Confirm title={`Delete ${capitalize(stage)}`}
                      content="Are you sure you want to permanently  delete the selected item"
                      onSubmit={() => {
                        setLoading(true);
                        dispatch(deleteOrders({
                          order_ids: orderId,
                        })).then(res => {
                          setLoading(false);
                          if (!res.error) {
                            dispatch(showMessage({ message: "Selected items has been deleted successfully", type: 'success' }))
                            dispatch(getOrders({ params: { stage }, goToFirstPage: true }))
                          }
                        })
                      }}
                    />
                  ),
                })
              );

            }}>
            {` Delete ${capitalize(stage)}`}
          </Button>
        )
      }

    </Toolbar >
  ) : (
    <></>
  )}
  </>
  );
}

export default OrdersToolBar;
