import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { Fragment, forwardRef, useEffect } from 'react';
import { AuraSimpleTableHead } from 'app/main/apps/common/AuraSimpleTable';
import { cloneDeep } from 'lodash';
import { getDiscountAmtFromPercentage } from 'app/main/apps/common/AuraFunctions';

// This component is used only for printing will not visible in Ui
const PrintableColumns = forwardRef(({ header, data , showDiscount}, ref) => {
    const HeaderComponent = header?.component;

    const getTableHeader = () => {
        if(showDiscount === true) {
            return [
                { name: 'No ' },
                { name: 'Name' },
                { name: 'Sub Type' },
                { name: 'Shape' },
                { name: 'Polish' },
                { name: 'Quantity' },
                { name: 'Reference No' },
                { name: 'Decedent' },
                { name: 'Weight' },
                { name: 'Price', },
                { name: 'Total Weight', },
                { name: 'Discount', },
                { name: 'Amount' },
            ];
        }
        return [
            { name: 'No ' },
            { name: 'Name' },
            { name: 'Sub Type' },
            { name: 'Shape' },
            { name: 'Polish' },
            { name: 'Quantity' },
            { name: 'Reference No' },
            { name: 'Decedent' },
            { name: 'Weight' },
            { name: 'Price', },
            { name: 'Total Weight', },
            { name: 'Amount' },
        ];

    }

    const headerData = {
        ...cloneDeep(header.data),
        hideAmount: true,
        hideQuantity: true,
        showTotalWeight: false,
        showPrintAction: false,
        showDownloadAction: false,
    }

    function isShippingCost(orderDetail) {
        return orderDetail?.name === "Shipping Cost" && orderDetail?.order_id === null;
      }

    return (
        <div ref={ref} className='print-style'>
            <HeaderComponent {...headerData} />
            <div className="table-responsive w-full">
                <Table className="simple w-full min-w-full">
                    <AuraSimpleTableHead
                        headerList={getTableHeader()}
                        classes={{ cnCell: 'font-semibold text-sm' }}
                    />
                    <TableBody>
                        {data?.map((orderDetail, index) => {
                            return (
                                <Fragment key={index}>
                                    <TableRow>
                                        <TableCell className='p-0' scope="row">
                                            <Typography className="text-sm">{index + 1}</Typography>
                                        </TableCell>
                                        <TableCell className='p-0' scope="row">
                                            <Typography className="text-sm">{orderDetail.name}</Typography>
                                        </TableCell>
                                        <TableCell className='p-0' scope="row">
                                            <Typography className="text-sm">{orderDetail.subType}</Typography>
                                        </TableCell>
                                        <TableCell className='p-0' scope="row">
                                            <Typography className="text-sm">{orderDetail.type}</Typography>
                                        </TableCell>
                                        <TableCell className='p-0' scope="row">
                                            <Typography className="text-sm">{orderDetail.polish}</Typography>
                                        </TableCell>
                                        <TableCell className='p-0' scope="row">
                                            <Typography className="text-sm">{orderDetail.quantity}</Typography>
                                        </TableCell>
                                        <TableCell className='p-0' scope="row">
                                            <Typography className="text-sm">{orderDetail.customer_provider_number}</Typography>
                                        </TableCell>
                                        <TableCell className='p-0' scope="row">
                                            <Typography className="text-sm">{orderDetail.decedent}</Typography>
                                        </TableCell>
                                        <TableCell className='p-0' scope="row">
                                            <Typography className="text-sm">{orderDetail.weight && orderDetail.weight > 0 ? `${orderDetail.weight} Lbs` : ''}</Typography>
                                        </TableCell>
                                        <TableCell className='p-0' scope="row">
                                            <Typography className="text-sm">{orderDetail.unit_price && '$'}{orderDetail.unit_price}</Typography>
                                        </TableCell>
                                        <TableCell className='p-0' scope="row">
                                            <Typography className="text-sm">{orderDetail.quantity && orderDetail.quantity > 0  && orderDetail.weight && orderDetail.weight > 0 ? `${orderDetail.quantity * orderDetail.weight} Lbs` : ''}</Typography>
                                        </TableCell>
                                        {showDiscount === true &&
                                            <TableCell className='p-0' scope="row">
                                                {!isShippingCost(orderDetail) && <Typography className="text-sm">
                                                    ${getDiscountAmtFromPercentage(orderDetail?.discount, orderDetail?.unit_price, orderDetail?.quantity)}
                                                </Typography>}
                                            </TableCell>
                                        }
                                        <TableCell className='p-0' scope="row">
                                            <Typography className="text-sm">{orderDetail.amount !== null && '$'}{orderDetail.amount}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
});

export default PrintableColumns;