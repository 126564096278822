import FuseUtils from '@fuse/utils';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import _ from '@lodash';
import AuraTable from 'app/main/apps/common/AuraTable';
import withRouter from '@fuse/core/withRouter';
import { useLocation } from 'react-router-dom';
import { getUpdateStatusValues, setOrdersSearchText, setResetFilters } from '../store/ordersSlice';
import { getParams, mapParams } from '../../common/AuraFunctions';

function EntitiesContent(props) {
  const {
    isManage,
    role,
    entitiesSelector,
    getEntities,
    getColumns,
    selectEntities,
    getEntityStatuses,
    listType,
    statusCategory,
    handleSearchText,
    filterType,
    includeInvoice,
    includeArchive,
    type,
    entitiesFor,
    headerTitle
  } = props;

  const dispatch = useDispatch();
  const entities = useSelector(selectEntities);
  const searchText = useSelector(({ eCommerceApp }) => eCommerceApp[entitiesSelector].searchText);
  const customer = useSelector(({ eCommerceApp }) => eCommerceApp[entitiesSelector].customer);
  const supplier = useSelector(({ eCommerceApp }) => eCommerceApp.orders.supplier);
  const resetFilter = useSelector(({ eCommerceApp }) => eCommerceApp.orders.resetFilter);
  const salesPerson = useSelector(({ eCommerceApp }) => eCommerceApp.orders.salesPerson);
  const listLoading = useSelector(({ eCommerceApp }) => eCommerceApp[entitiesSelector].loading);
  const [filteredData, setFilteredData] = useState(null);
  const toolbarParam = { pageName: entitiesSelector, selectionMsg: `${listType} Selected`, options: filterType, stage: type };
  const [headers, setHeaders] = useState({});
  const [params, setParams] = useState({});
  const { orderListHeaders } = useSelector(({ eCommerceApp }) => eCommerceApp[entitiesSelector]);
  const { selectedValue } = useSelector(({ eCommerceApp }) => eCommerceApp?.orders);
  const [showNoDataMessage, setShowNoDataMessage] = useState(null);
  const [pageNoUpdated, setPageNoUpdated] = useState(false);
  const isSupplierInvoice = entitiesFor && entitiesFor === 'supplier-invoices';
  const ref = useRef()
  const finalParam = {
    ...params,
    [props.type === 'approval' ? 'title' : 'stage']: props.type === 'approval' ? 'proof' : props.type,
    ...(isSupplierInvoice ? { is_supplier: true } : {}),
  }

  useEffect(() => {
    return () => {
      // following code will run on unmount.
      setShowNoDataMessage(null)
    };
  }, []);

  const onPageChange = (value) => {
    setPageNoUpdated(true);
    setHeaders(prev => ({ ...prev, "App-Pagination-Num": value }))
  };

  const onPerPageChange = (value) => {
    setHeaders(prev => ({ ...prev, "App-Pagination-Limit": value }))
  };

  const onSorting = (key, desc) => {
    const sortvalue = `${key},${desc ? 'DESC' : 'ASC'}`
    if (key)
      setHeaders(prev => ({ ...prev, "App-Pagination-Sort": sortvalue }))
    else {
      const temp = { ...headers, "App-Pagination-Sort": desc };
      // delete temp['App-Pagination-Sort']
      setHeaders(temp)
    }
  }

  const serverPaginationProps = {
    changePage: onPageChange,
    perPage: onPerPageChange,
  };


  const debounceFn = useCallback(
    debounce((header, param) => {
      setShowNoDataMessage(null)
      dispatch(getEntities({
        params: {
          ...param,
        }, headers: header
      }))
        .unwrap()
        .then((response) => {
          if (response?.length <= 0) {
            setShowNoDataMessage(true)
          }
        })
    }, 500),
    []
  );

  const updateParams = () => {
    const computedParams = getParams()
    setHeaders(computedParams.headerObj)
    setParams(computedParams.filterObj)
  }

  useEffect(() => {
    if (ref.current) {
      setHeaders({});
      setParams({});
      setShowNoDataMessage(null)
    }
  }, [entitiesFor])

  useEffect(() => {
    updateParams()
    setShowNoDataMessage(null)
  }, [customer, supplier, salesPerson, filterType, selectedValue, includeInvoice, includeArchive])

  useEffect(() => {
    if (ref.current) {
      let removeKey = ['stage', 'title']
      if(pageNoUpdated === false) {
        removeKey = ['stage', 'title', 'pageNo']
      }
      setPageNoUpdated(false)
      const searchParams = new URLSearchParams(mapParams({ ...finalParam, ...headers }, removeKey));
      // replacestate function will not rerender the page
      if (searchParams.toString())
        window.history.replaceState(null, "", `${window.location.href.split('?')[0]}?${searchParams.toString()}`);
    }
    else updateParams()
    debounceFn(headers, finalParam)
    ref.current = true
    setShowNoDataMessage(null)
  }, [dispatch, debounceFn, headers, params,]);

  useEffect(() => {
    if (resetFilter) {
      updateParams()
      debounceFn(headers, finalParam)
      dispatch(setResetFilters(false))
    }
  }, [debounceFn, headers, params, resetFilter])

  useEffect(() => {
    dispatch(
      getEntityStatuses({
        params: { c: statusCategory },
      })
    );

    if (props.type === 'order') {
      dispatch(
        getUpdateStatusValues({ c: statusCategory })
      );
    }
  }, [dispatch, statusCategory, props.entitiesFor]);

  useEffect(() => {
    function getFilteredArray(_entities, _searchText) {
      if (_searchText.length === 0) {
        return _entities;
      }
      return FuseUtils.filterArrayByString(_entities, _searchText);
    }

    if (entities) {
      setFilteredData(getFilteredArray(entities, searchText));
    }
  }, [entities, searchText]);

  return (
    <div className="flex-auto p-24 sm:p-40 h-full">
      <AuraTable
        columns={getColumns(isManage, customer, role)}
        data={entities}
        resetFilters={props.resetFilters}
        isManage={props.isManage}
        toolbarParam={toolbarParam}
        headers={orderListHeaders}
        serverPaginationProps={serverPaginationProps}
        params={params}
        updateParams={(key, value) => setParams(prev => ({ ...prev, [key]: value }))}
        onSorting={onSorting}
        loading={listLoading}
        showListInfo={showNoDataMessage && showNoDataMessage === true}
        loaderProps={{
          show: !filteredData || filteredData.length === 0,
          loading: listLoading,
          message: `There are no ${listType}!`
        }}
        loaderWithSpace
        noDataMessage={`There are no ${_.lowerCase(headerTitle)}!`}
      />
    </div>
  );
}
export default withRouter(EntitiesContent);
