import { round, sumBy, toNumber } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  CircularProgress,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Badge,
  Switch,
  Box
} from "@mui/material";
import { amber } from "@mui/material/colors";
import { AuraSimpleTableHead } from "app/main/apps/common/AuraSimpleTable";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import { useDispatch, useSelector } from "react-redux";
import { customLayout } from "app/store/auth/cartsSlice";
import DeleteConfirmation from "../Dialogs/DeleteConfirmation";
import ApprovalContent from "../Dialogs/ApprovalContent";
import { productInitValues } from "../../product/ProductBase";
import { getProductDetails, openProductDialog } from "../../store/productSlice";

function OrderItems({
  fields = [],
  control,
  hasManageProductPermission,
  isReadOnly,
  isview,
  remove,
  setDetails,
  setOpenDialog,
  getVASPricing,
  orderDetails,
  type,
  handleCustomzation,
  directShipment,
  priceEditable = false,
  hideQty,
  orderType,
  param,
  pageType,
  hideAdditionalFields,
  isCustomer
}) {
  const { orderId } = useParams();
  const [loading, setLoading] = useState(false);
  const [approvalDialog, setApprovalDialog] = useState({});
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState({
    open: false
  })
  const orderData = useSelector(({ eCommerceApp }) => {
    return eCommerceApp.order ? eCommerceApp.order.data : null;
  });
  const {
    formState: { errors },
    watch, getValues, setValue
  } = useFormContext();

  const formData = getValues()

  const watchProducts = watch("products");
  const isQuoteEdit = orderType === 'quote' && pageType === 'edit'
  const isQuote = orderType === 'quote' || param === 'convert-to-quote'

  useEffect(() => {
    setValue('weight', Number(getValues()?.weight))
  }, [setValue])

  const handleDeleteDialog = (show, currentIndex) => {
    setDeleteConfirmationDialog((prev) => ({ ...prev, open: show, index: currentIndex }))
  }

  const handleDeleteSubmit = () => {
    remove(deleteConfirmationDialog.index)
    setDeleteConfirmationDialog({ open: false })
  }

  const hideVas = [
    "Drawing Revision Requested",
    "Received",
    "Drawing Rejected",
  ].includes(orderData?.status?.name);

  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname, search } = location;
  const queryString = search.startsWith('?') ? search.slice(1) : search;
  // Split the query string into key-value pairs
  const queryParams = new URLSearchParams(queryString);
  // Get the value of the "type" parameter
  const typeValue = queryParams.get("type");
  const hideFields = true
  const showDrawingApproval = (data) => {
    // const textData = data?.vas?.text?.[0];
    // const imageData = data?.vas?.images?.[0];

    // const isVas = textData?.name || textData?.value || textData?.url || imageData?.name || imageData?.value || imageData?.url;
    return (orderData.is_drawing_approval || data?.is_custom || data?.product?.is_custom || isCustomer);
  }
  const showProofApproval = (data) => {
    const textData = data?.vas?.text?.[0];
    const imageData = data?.vas?.images?.[0];

    const isVas = textData?.name || textData?.value || textData?.url || imageData?.name || imageData?.value || imageData?.url;
    return (orderData.is_proof_approval || isVas) && !isCustomer;
  }

  const orderCustomzation = (id) => {
    dispatch(customLayout(id)).then(({ payload }) => {
      if (payload === null) {
        setOpenDialog(true);
      }
    });
  };

  const calculatePrice = (fob, qty, orderDetail) => {
    const { weight, margin, tariff, shipping } = orderDetail.product || {}
    const shipVal = Number(weight) * Number(shipping);
    const tariffVal = Number(fob) * (Number(tariff) / 100);
    const value = (Number(fob) + shipVal + tariffVal) / (1 - Number(margin / 100));
    const priceVal = value && Number(value) ? value.toFixed(0) : 0;
    const wholeSalePrice = Number(priceVal)
    return Math.round(wholeSalePrice)
  }

  function calculateAmount(price, quantity, discountPercentage, textPrice = 0, imagePrice = 0) {
    const total = price * quantity;
    const discount = total * (discountPercentage / 100);
    const amount = (total - discount) + textPrice + imagePrice;
    return parseFloat(amount.toFixed(2));
  }

  const orderCols = (orderDetail, index) => {
    const orderDetailForm = formData?.order_details[index]

    const textData = orderDetailForm?.vas?.text?.[0];
    const imageData = orderDetailForm?.vas?.images?.[0];

    const showVasBadge = textData?.name || textData?.value || textData?.url || imageData?.name || imageData?.value || imageData?.url;
    // const showVasBadge = !((orderDetailForm?.vas?.images?.length > 0 && orderDetailForm?.vas?.images?.[0]?.id > 0) || (orderDetailForm?.vas?.text?.length > 0 && orderDetailForm?.vas?.text?.[0]?.id > 0))
    let vasTooltip;

    if (isview) {
      vasTooltip = "View Attributes";
    } else if (orderDetail.vas) {
      vasTooltip = "Edit Attributes";
    } else {
      vasTooltip = "Sandblasting / Etching";
    }
    return (
      <>
        <td className="w-100 px-8 py-4">
          <span className="truncate">{orderDetail?.product?.sub_type?.name}</span>
        </td>

        <td className="w-106 px-8 py-4">
          <Controller
            control={control}
            name={`order_details.${index}.customer_provider_number`}
            render={({ field }) => (
              <>
                {!hideFields && (
                  <TextField
                    {...field}
                    variant="standard"
                    fullWidth
                    className="p-0"
                    size="small"
                    InputProps={{
                      classes: {
                        input: "FuseSearch-input py-4 h-20 w-92",
                        notchedOutline: "rounded-8",
                      },
                      readOnly: !hasManageProductPermission || isReadOnly,
                    }}
                  />
                )}
                {hideFields && (
                  <Tooltip title={field.value}>
                    <Typography className="h-20 w-92 truncate">{field.value}</Typography>
                  </Tooltip>
                )}
              </>
            )}
          />
        </td>
        <td className="w-106 px-8 py-4">
          <Controller
            control={control}
            name={`order_details.${index}.decedent`}
            render={({ field }) => (
              <>
                {!hideFields && (
                  <TextField
                    {...field}
                    variant="standard"
                    fullWidth
                    className="p-0"
                    size="small"
                    InputProps={{
                      classes: {
                        input: "FuseSearch-input py-4 h-20 w-96",
                        notchedOutline: "rounded-8",
                      },
                      readOnly: !hasManageProductPermission || isReadOnly,
                    }}
                  />
                )}
                {hideFields && (
                  <Tooltip title={field.value}>
                    <Typography className="h-20 w-64 truncate">{field.value}</Typography>
                  </Tooltip>
                )}
              </>
            )}
          />
        </td>
        <td className="w-100 px-8 py-4">
          <span className="truncate">
            <Controller
              control={control}
              name={`order_details.${index}.quantity`}
              render={({ field }) => (
                <>
                  {!hideFields && (
                    <TextField
                      {...field}
                      onChange={(ev) => {
                        field.onChange(Number(ev.target.value));
                        setValue(`order_details.${index}.unit_price`, calculatePrice(getValues().order_details[index].unit_cost, ev.target.value, orderDetail))
                      }}
                      value={Number(field.value) || ""}
                      type="number"
                      variant="standard"
                      fullWidth
                      className="p-0"
                      size="small"
                      InputProps={{
                        classes: {
                          input: "FuseSearch-input py-4 h-20 w-64",
                          notchedOutline: "rounded-8",
                        },
                        readOnly: !hasManageProductPermission || isReadOnly,
                      }}
                    />
                  )}
                  {hideFields && (
                    <Typography className="h-20 w-62">{field.value}</Typography>
                  )}
                </>
              )}
            />
          </span>
        </td>
        {
          isQuoteEdit || param === 'convert-to-quote' ? (
            <td className="w-100 px-8 py-4 text-center">
              <span className="truncate">
                <Controller
                  control={control}
                  name={`order_details.${index}.weight`}
                  rules={{
                    required: {
                      value: true,
                      message: 'Required'
                    }
                  }}
                  render={({ field }) => (
                    <>
                      {!hideFields && (
                        <TextField
                          {...field}
                          onChange={(ev) => {
                            field.onChange(Number(ev.target.value));
                            if (isQuoteEdit) {
                              setValue(`order_details.${index}.unit_price`, calculatePrice(orderDetails))
                            }
                          }}
                          value={Number(field.value) || ""}
                          type="number"
                          variant="standard"
                          fullWidth
                          className="p-0"
                          size="small"
                          error={!!errors?.order_details?.[index]?.weight}
                          helperText={errors?.order_details?.[index]?.weight?.message}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">lbs</InputAdornment>
                            ),
                            classes: {
                              input: "FuseSearch-input py-4 h-20 w-64",
                              notchedOutline: "rounded-8",
                            },
                            readOnly: !hasManageProductPermission || isReadOnly,
                          }}
                        />
                      )}
                      {hideFields && (
                        <Typography className="h-20">{field.value} lbs</Typography>
                      )}
                    </>
                  )}
                />
              </span>
            </td>
          ) : (
            <td className="w-100 px-8 py-4">
              <span className="truncate">
                {orderDetail?.product?.weight
                  ? `${orderDetail?.product?.weight} lbs`
                  : ""}
              </span>
            </td>
          )
        }
        {!hideAdditionalFields && <td className="w-100  px-8 py-4">
          <span className="truncate">
            <Controller
              control={control}
              name={`order_details.${index}.unit_cost`}
              render={({ field }) => (
                <>
                  {!hideFields && (
                    <TextField
                      {...field}
                      onChange={(ev) => {
                        field.onChange(Number(ev.target.value));
                        setValue(`order_details.${index}.unit_price`, calculatePrice(ev.target.value, getValues().order_details[index].quantity, orderDetail))
                      }}
                      type="number"
                      variant="standard"
                      fullWidth
                      className="p-0"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        classes: {
                          input: "FuseSearch-input py-4 h-20",
                          notchedOutline: "rounded-8",
                        },
                        readOnly: !hasManageProductPermission || isReadOnly,
                      }}
                    />
                  )}
                  {hideFields && (
                    <Typography className="h-20 w-62">{field.value !== null && '$'} {field.value}</Typography>
                  )}
                </>
              )}
            />
          </span>
        </td>}
        {!hideAdditionalFields && <td className="w-100  px-8 py-4">
          <span className="truncate">
            <Controller
              control={control}
              name={`order_details.${index}.unit_price`}
              render={({ field }) => (
                <>
                  {(!hideFields && !orderDetail?.product?.is_custom) && (
                    <TextField
                      {...field}
                      onChange={(ev) => {
                        field.onChange(Number(ev.target.value));
                      }}
                      value={field.value || ""}
                      type="number"
                      variant="standard"
                      fullWidth
                      className="p-0"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        classes: {
                          input: "FuseSearch-input py-4 h-20",
                          notchedOutline: "rounded-8",
                        },
                        readOnly: !hasManageProductPermission || isReadOnly,
                      }}
                    />
                  )}
                  {(hideFields || orderDetail?.product?.is_custom) && (
                    <Typography className="">{field.value !== null && '$'} {field.value}</Typography>
                  )}
                </>
              )}
            />
          </span>
        </td>}
        {!isCustomer && <td className="w-100 px-8 py-4">
          <span className="truncate">
            {orderDetail.discount && `${orderDetail.discount}%`}
          </span>
        </td>}
        <td className="w-100 px-8 py-4">
          <span className="truncate">
            {Number(orderDetail?.product?.weight) *
              Number(orderDetails?.[index]?.quantity)}{" "}
            lbs
          </span>
        </td>
        {
          !hideAdditionalFields && (
            <td className="w-100 px-8 py-4">
              <span className="truncate">
                $ {calculateAmount(orderDetail?.unit_price, orderDetails?.[index]?.quantity, orderDetail.discount)}
              </span>
            </td>
          )
        }
        <td>
          <td>
            {orderDetail?.product?.is_custom && (
              <Controller
                name={`order_details.${index}.is_has_drawing`}
                type="checkbox"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Switch
                      checked={value}
                      className={`${isview ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                      onChange={(ev) => onChange(ev.target.checked)}
                      disabled={isview}
                    />
                  </>
                )}
              />
            )}
          </td>
        </td>

        <td className="text-center">
          {hasManageProductPermission && (
            <div className="flex">
              {!isview && (
                <IconButton
                  className="cl-icon "
                  size="small"
                  onClick={() => {
                    handleDeleteDialog(true, index)
                    // remove(index);
                  }}
                >
                  <Icon>clear</Icon>
                </IconButton>
              )}

              {(isview ? orderDetail.vas : true) && (
                <Tooltip title={vasTooltip}>
                  <Badge color="error" className="action-badge" overlap="circular" badgeContent={<Icon sx={{ fontSize: '12px', rotate: '270deg' }} fontSize="small">attachment</Icon>} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                    sx={{ padding: '0px' }}
                    invisible={!showVasBadge}
                  >
                    <FuseSvgIcon
                      className="custom-icon"
                      size={30}
                      color={orderDetail.vas ? "secondary" : "action"}
                      onClick={() => {
                        orderCustomzation(orderDetail?.product_id);
                        // setOpenDialog(true);
                        setDetails({
                          vas: orderDetail.vas,
                          editable: !orderDetail.createdAt,
                          index,
                        });
                        handleCustomzation(true);
                      }}
                    >
                      material-outline:brush
                    </FuseSvgIcon>
                  </Badge>
                </Tooltip>
              )}

              {(isview ? orderDetail.drawing_documents : showDrawingApproval(orderDetail)) && (
                <Tooltip title="Attach Diagram">
                  <Badge color="error" className="action-badge" overlap="circular" badgeContent={<Icon sx={{ fontSize: '12px', rotate: '270deg' }} fontSize="small">attachment</Icon>} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                    invisible={!(orderDetailForm?.drawing_documents?.length > 0 || orderDetailForm?.new_drawing_documents?.length > 0)}
                  >
                    <FuseSvgIcon
                      className="custom-icon"
                      size={24}
                      onClick={() => {
                        setApprovalDialog({
                          type: "drawingApproval",
                          title: "Attach Diagram",
                          open: true,
                          attachmentProps: {
                            payload: { image_customisation_type: "drawing" },
                            attachmentsType: `order_details.${index}.drawing_documents`,
                            newAttachmentsType: `order_details.${index}.new_drawing_documents`,
                          },
                          isview
                        });
                      }}
                    >
                      material-solid:attach_file
                    </FuseSvgIcon>
                  </Badge>
                </Tooltip>
              )}
              {(isview ? orderDetail.proof_documents : showProofApproval(orderDetail)) && (
                <Tooltip title="Proof">
                  <Badge color="error" className="action-badge" overlap="circular" badgeContent={<Icon sx={{ fontSize: '12px', rotate: '270deg' }} fontSize="small">attachment</Icon>} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                    invisible={!(orderDetailForm?.proof_documents?.length > 0 || orderDetailForm?.new_proof_documents?.length > 0)}
                  >
                    <FuseSvgIcon
                      className="custom-icon"
                      size={30}
                      onClick={() => {
                        setApprovalDialog({
                          type: "proofApproval",
                          title: "Proof Documents",
                          open: true,
                          attachmentProps: {
                            payload: { image_customisation_type: "proof" },
                            attachmentsType: `order_details.${index}.proof_documents`,
                            newAttachmentsType: `order_details.${index}.new_proof_documents`,
                          },
                          isview
                        });
                      }}
                    >
                      material-solid:attach_file
                    </FuseSvgIcon>
                  </Badge>
                </Tooltip>
              )}

              {!isview && (
                <Tooltip
                  title="Edit Product"
                >
                  <FuseSvgIcon
                    className="custom-icon"
                    size={30}
                    onClick={() => {
                      setLoading(true); 
                      if (orderDetail?.product_id) {
                        dispatch(
                          getProductDetails({
                            id: orderDetail.product_id,
                            params: {},
                          })
                        ).then(({ payload }) => {
                          dispatch(
                            openProductDialog({
                              data: productInitValues({ ...payload, discount: orderDetail.discount, order_quantity: orderDetail.quantity, customer_provider_number: orderDetail.customer_provider_number, comments: orderDetail.comments, decedent: orderDetail.decedent }),
                              param: {
                                name: "edit_custom_product",
                                title: orderDetail?.product?.is_custom ? "Edit Custom Product" : "Edit Inventory Product",
                                orderType: param,
                                stage: orderType,
                                isReadOnly: false,
                                hideDialogActions: false,
                                isCustomProduct: orderDetail?.product?.is_custom,
                                hideAdditionalFields
                              },
                            })
                          );
                                  setLoading(false); 
                       });
                       } else {
                                  setLoading(false); 
                  }
               }}
                  >
                    material-solid:create
                  </FuseSvgIcon>
                </Tooltip>
              )}
              {orderDetail?.product?.is_custom && !isview && (
                <Tooltip
                  title="Copy Product"
                >
                  <FuseSvgIcon
                    className="custom-icon"
                    size={30}
                    onClick={() => {
                      if (orderDetail?.product_id) {
                        dispatch(
                          getProductDetails({
                            id: orderDetail.product_id,
                            params: {},
                          })
                        ).then(({ payload }) =>
                          dispatch(
                            openProductDialog({
                              data: productInitValues({ ...payload, discount: orderDetail.discount, order_quantity: orderDetail.quantity, customer_provider_number: orderDetail.customer_provider_number, comments: orderDetail.comments, decedent: orderDetail.decedent }),
                              param: {
                                name: "custom_product",
                                title: "Add Custom Product",
                                orderType: param,
                                stage: orderType,
                                isReadOnly: false,
                                hideDialogActions: false,
                                cloneProduct: true,
                                isCustomProduct: orderDetail?.product?.is_custom,
                                hideAdditionalFields
                              },
                            })
                          )
                        );
                      }
                    }}
                  >
                    material-solid:content_copy
                  </FuseSvgIcon>
                </Tooltip>
              )}
            </div>
          )}
        </td>

      </>
    )
  };

  const reOrderCols = (orderDetail, index) => (
    <>
      {!hideQty && <td className="w-64 px-8 py-4">
        <span className="truncate">
          <Controller
            control={control}
            name={`products.${index}.quantity`}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(ev) => {
                  if (ev.target.value !== "e")
                    field.onChange(Number(ev.target.value));
                }}
                type="number"
                variant="standard"
                fullWidth
                className="p-0"
                size="small"
                disabled={directShipment}
                InputProps={{
                  classes: {
                    notchedOutline: "rounded-8",
                  },
                  inputProps: { min: 0 },
                }}
                error={!!errors?.products?.[index]?.quantity}
                helperText={errors?.products?.[index]?.quantity?.message}
              />
            )}
          />
        </span>
      </td>}
      <td className="w-72 px-8 py-4">
        {
          !hideQty ? (
            <span className="truncate">
              {directShipment || priceEditable ? (
                <Controller
                  control={control}
                  name={`products.${index}.unit_cost`}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(ev) => {
                        if (ev.target.value !== "e")
                          field.onChange(ev.target.value ? Number(ev.target.value) : '');
                      }}
                      type="number"
                      variant="standard"
                      fullWidth
                      className="p-0"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        classes: {
                          input: "FuseSearch-input py-4 h-20 w-64",
                          notchedOutline: "rounded-8",
                        },
                      }}
                      error={!!errors?.products?.[index]?.unit_cost}
                      helperText={errors?.products?.[index]?.unit_cost?.message}
                    />
                  )}
                />
              ) : (
                `$${orderDetail.product.unit_cost}`
              )}
            </span>
          ) : '-'
        }
      </td>
    </>
  );

  const orderPricingNewCols = (
    <TableBody>
      <TableRow>
        <TableCell>
          <Typography className="font-normal text-13 ">Total Item(s)</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography className="font-normal">
            {sumBy(orderDetails, function (o) {
              return toNumber(o?.quantity);
            })}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography className="font-normal text-13 ">Total Weight</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography className="font-normal">
            {sumBy(orderDetails, function (o) {
              return toNumber(o?.quantity) * toNumber(o?.product?.weight);
            })}{" "}
            lbs
          </Typography>
        </TableCell>
      </TableRow>
      {
        !hideAdditionalFields && (
          <TableRow>
            <TableCell>
              <Typography className="font-normal text-13 ">Total Amount</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography className="font-normal">
                $ {parseFloat(sumBy(orderDetails, function (o) {
                  const textPrice = o?.vas?.textPrice
                  const imagePrice = o?.vas?.imagePrice
                  return calculateAmount(o?.unit_price, o?.quantity, o?.discount, textPrice, imagePrice);
                })).toFixed(2)}
              </Typography>
            </TableCell>
          </TableRow>
        )
      }
    </TableBody>
  )

  const reOrderPricingNewCols = (
    <TableBody>
      {!hideQty && (
        <>
          <TableRow>
            <TableCell>
              <Typography className="font-normal text-13 ">Total Item(s)</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography className="font-normal">
                {sumBy(watchProducts, function (o) {
                  return toNumber(o?.quantity);
                })}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography className="font-normal text-13 ">Total Weight</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography className="font-normal">
                {sumBy(watchProducts, function (o) {
                  return toNumber(o.quantity) * toNumber(o.weight);
                })}{" "}
                lbs
              </Typography>
            </TableCell>
          </TableRow>
        </>
      )}
      <TableRow>
        <TableCell>
          <Typography className="font-normal text-13 ">Total Price</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography className="font-normal">
            $
            {`  ${round(
              sumBy(watchProducts, function (o) {
                if (hideQty) return toNumber(o?.vas.imagePrice) + toNumber(o?.vas.textPrice);
                return toNumber(o?.quantity) * toNumber(o?.unit_cost);
              }),
              2
            )} `}
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  )

  // const orderPricingCols = (
  //   <>
  //     <td className="py-16">
  //       {sumBy(orderDetails, function (o) {
  //         return toNumber(o?.quantity);
  //       })}
  //     </td>
  //     <td />
  //     {
  //       isQuote && (
  //         <>
  //           <td className="py-16" />
  //           <td className="py-16" />
  //           <td className="py-16" />
  //         </>
  //       )
  //     }
  //     {
  //       !isQuote && (
  //         <td className="py-16">
  //           {
  //             !hideAdditionalFields && (
  //               <>            $
  //                 {`  ${round(
  //                   sumBy(orderDetails, function (o) {
  //                     const price = isQuoteEdit ? o?.unit_cost : o?.unit_price
  //                     return (
  //                       toNumber(o?.quantity) * toNumber(price) +
  //                       (o?.vas?.imagePrice || 0) +
  //                       (o?.vas?.textPrice || 0)
  //                     );
  //                   }),
  //                   2
  //                 )} `}</>
  //             )
  //           }
  //         </td>
  //       )
  //     }
  //     <td className="py-16">
  //       {sumBy(orderDetails, function (o) {
  //         return toNumber(o?.quantity) * toNumber(o?.product?.weight);
  //       })}{" "}
  //       lbs
  //     </td>
  //   </>
  // );

  // const reOrderPricingcols = (
  //   <>
  //     <td className="py-16 px-9">
  //       {
  //         !hideQty && (
  //           <>
  //             {sumBy(watchProducts, function (o) {
  //               return toNumber(o.quantity) * toNumber(o.weight);
  //             })}
  //             lbs
  //           </>
  //         )
  //       }
  //     </td>
  //     {!hideQty && <td className="py-16 px-9">
  //       {sumBy(watchProducts, function (o) {
  //         return toNumber(o?.quantity);
  //       })}
  //     </td>}
  //     <td className="py-16 px-9">
  //       $
  //       {`  ${round(
  //         sumBy(watchProducts, function (o) {
  //           if (hideQty) return toNumber(o?.vas.imagePrice) + toNumber(o?.vas.textPrice);
  //           return toNumber(o?.quantity) * toNumber(o?.unit_cost);
  //         }),
  //         2
  //       )} `}
  //     </td>
  //   </>
  // );

  // const hidePriceFields = () => {
  //   if (orderType === 'rfq') return true
  //   return false
  // }

  const headers = [
    { name: "No" },
    { name: "Name" },
    { name: "SubType" },
    { name: "Weight" },
    { name: "Quantity", hide: hideQty },
    { name: "Price" },
  ];

  const orderTableHeaders = [
    { name: "No" },
    { name: "Name" },
    { name: "Sub Type" },
    { name: "Reference No" },
    { name: "Decedent" },
    { name: "Qty" },
    { name: "Weight" },
    { name: "FOB", hide: hideAdditionalFields },
    { name: "Unit Price", hide: hideAdditionalFields },
    { name: "Discount", hide: isCustomer },
    {
      name: "Total Weight"
    },
    { name: "Total Amount", hide: hideAdditionalFields },
    { name: "Request product drawing" },
    { name: "Action" },
  ];

  return (
    <div className="table-responsive">
        <div className='w-full'>
        {loading && (
          <div className="my-20 flex justify-center items-center">
        <CircularProgress />
          </div>
        )}
      <table className="w-full">
      {loading ? null : (
       <>
        <AuraSimpleTableHead
          headerList={type === "order" ? orderTableHeaders : headers}
          classes={{ cnCell: "font-semibold px-8 py-8 w-300 cell-class" }}
        />
        <tbody>
          {fields.map((data, index) => {
            console.log('data', data)
            const orderDetail = type === "order" ? data : { product: data };
            return (
              <Fragment key={data.id || index}>
                <tr>
                  <td className="w-40  px-14 py-8 w-64 px-8 py-4">{index + 1}</td>
                  <td className="w-64 px-8 py-4 product-name">
                  <Box display="flex" alignItems="center" width="100%">
                  {data?.comments && (
                  <IconButton color="inherit" className="comment-icon" 
                      onClick={() => {
                        if (orderDetail?.product_id || orderDetail?.product?.product_id || orderDetail?.product?.id) {
                          dispatch(
                            getProductDetails({
                              id:
                                orderDetail.product_id || orderDetail.product.product_id || orderDetail.product.id,
                              params: {},
                            })
                          ).then(({ payload }) =>
                            dispatch(
                              openProductDialog({
                                data: productInitValues({ ...payload, discount: orderDetail.discount, order_quantity: orderDetail.quantity, customer_provider_number: orderDetail.customer_provider_number, comments: orderDetail.comments, decedent: orderDetail.decedent }),
                                param: {
                                  name: "edit_custom_product",
                                  title: "Edit Custom Product",
                                  orderType: param,
                                  stage: orderType,
                                  isReadOnly: false,
                                  hideDialogActions: false,
                                  isCustomProduct: orderDetail?.product?.is_custom,
                                  hideAdditionalFields
                                },
                              })
                            )
                          );
                        }
                      }}
                      invisible={!data?.comments}
                      style={{ backgroundColor: 'red',borderRadius: '50%', padding: '5px', }}
                    >
                      <Tooltip title="View Comments">
                           <Icon sx={{ fontSize: '16px', color: 'white' }}>visibility</Icon>
                      </Tooltip>
                      </IconButton>
                      )}
                      <Tooltip title={orderDetail?.product?.name || ''}>
                      <Typography
                        // For direct shipment the product id will be in product_id
                        // to={`/products/${orderDetail.product.product_id || orderDetail.product.id}`}
                        onClick={() => {
                          if (orderDetail?.product_id || orderDetail?.product?.product_id || orderDetail?.product?.id) {
                            dispatch(
                              getProductDetails({
                                id:
                                  orderDetail.product_id || orderDetail.product.product_id || orderDetail.product.id,
                                params: {},
                              })
                            ).then(({ payload }) =>
                              dispatch(
                                openProductDialog({
                                  data: productInitValues({ ...payload, discount: orderDetail.discount, order_quantity: orderDetail.quantity, customer_provider_number: orderDetail.customer_provider_number, comments: orderDetail.comments, decedent: orderDetail.decedent }),
                                  param: {
                                    name: "edit_custom_product",
                                    title: "Edit Custom Product",
                                    orderType: param,
                                    stage: orderType,
                                    isReadOnly: false,
                                    hideDialogActions: false,
                                    isCustomProduct: orderDetail?.product?.is_custom,
                                    hideAdditionalFields
                                  },
                                })
                              )
                            );
                          }
                        }}
                        className="truncate cursor-pointer"
                        style={{
                          color: amber[900],
                          textDecoration: "none", marginLeft: '8px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '270px', 
                        }}
                      >
                        {orderDetail?.product?.name}
                      </Typography>
                      </Tooltip>
                      {/* </div> */}
                     </Box>
                  </td>
                  {type !== "order" && (
                    <>
                      {" "}
                      <td className="px-8 py-4">
                        <span className="truncate">
                          {type === "supplierOrder"
                            ? orderDetail?.product?.sub_type_name
                            : orderDetail?.product?.subType
                          }
                        </span>
                      </td>
                    </>
                  )}
                  {type !== "order" && (
                    <td className="w-96 px-8 py-4">
                      <span className="truncate">
                        {orderDetail?.product?.weight
                          ? `${orderDetail?.product?.weight} lbs`
                          : ""}
                      </span>
                    </td>
                  )}
                  {type === "order"
                    ? orderCols(orderDetail, index)
                    : reOrderCols(orderDetail, index)}
                </tr>
                {
                  hideQty && (
                    <>
                      {
                        orderDetail?.product?.vas?.text?.length > 0 && (
                          <tr>
                            <td colSpan={1} />
                            <td colSpan={1} />
                            <td colSpan={1} />
                            <td className="px-8 py-4">Sandblasting*</td>
                            <td className="px-8 py-8">
                              <Controller
                                control={control}
                                name={`products.${index}.vas.textPrice`}
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'Required'
                                  }
                                }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    onChange={(ev) => {
                                      if (ev.target.value !== "e")
                                        field.onChange(Number(ev.target.value));
                                    }}
                                    className="mb-24"
                                    id="schedule"
                                    variant="standard"
                                    fullWidth
                                    size="small"
                                    type='number'
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    error={!!errors?.products?.[index]?.vas.textPrice}
                                    helperText={errors?.products?.[index]?.vas.textPrice?.message}
                                    required
                                  />
                                )}
                              />
                            </td>
                          </tr>
                        )
                      }
                      {
                        orderDetail?.product?.vas?.images?.length > 0 && (
                          <tr>
                            <td colSpan={1} />
                            <td colSpan={1} />
                            <td colSpan={1} />
                            <td className="px-8 py-4">Etching*</td>
                            <td className="px-8">
                              <Controller
                                control={control}
                                name={`products.${index}.vas.imagePrice`}
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'Required'
                                  }
                                }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    onChange={(ev) => {
                                      if (ev.target.value !== "e")
                                        field.onChange(Number(ev.target.value));
                                    }}
                                    className="mb-24"
                                    id="schedule"
                                    variant="standard"
                                    fullWidth
                                    size="small"
                                    type='number'
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    error={!!errors?.products?.[index].vas.imagePrice}
                                    helperText={errors?.products?.[index].vas.imagePrice?.message}
                                    required
                                  />
                                )}
                              />
                            </td>
                          </tr>
                        )
                      }
                    </>
                  )
                }
                {!isCustomer && !orderDetail?.vas?.stoneUrl && isQuote &&
                  (orderDetail?.vas?.text?.[0]?.name || orderDetail?.vas?.text?.[0]?.value || orderDetail?.vas?.text?.[0]?.url) &&
                  getVASPricing("Sandblasting", index)}
                {!isCustomer && !orderDetail?.vas?.stoneUrl && isQuote &&
                  (orderDetail?.vas?.images?.[0]?.name || orderDetail?.vas?.images?.[0]?.value || orderDetail?.vas?.images?.[0]?.url) &&
                  getVASPricing("Etching", index)}
                {orderDetail?.vas?.stoneUrl &&
                  orderDetail?.vas?.images.some(
                    (val) => val.name !== "choose-image.png"
                  ) &&
                  getVASPricing("Sandblasting", index)}
                {orderDetail?.vas?.stoneUrl &&
                  orderDetail?.vas?.text.some(
                    (val) =>
                      !["Name", "DATE", "Verse or Saying"].includes(val.value)
                  ) &&
                  getVASPricing("Etching", index)}
              </Fragment>
            );
          })}
          {/* <tr key="summary" className="font-semibold mt-16 border-t-3">
            <td colSpan={type === "order" ? "5" : "3"} className=" pr-24">
              TOTAL
            </td>
            {type === "order" ? orderPricingCols : reOrderPricingcols}
            <td colSpan="3" />
          </tr> */}
        </tbody>
        </>
      )}
      </table>
      {!loading && (
      <div className="flex flex-1 grow-0  justify-between">
        <div className="w-1/2">
          {/* {props.displayOptions?.extra?.component && <ExtraComponent {...control} {...(props.displayOptions.extra.data || {})} />} */}
        </div>
        <div className="flex-end flex-col w-1/3">
          <Table className="simple mt-20">
            {type === "order" ? orderPricingNewCols : reOrderPricingNewCols}
          </Table>
        </div>
      </div>
      )}
      <ApprovalContent
        {...approvalDialog}
        onClose={() => setApprovalDialog({ open: false })}
        heading="Add Documents"
      />
      <DeleteConfirmation
        open={deleteConfirmationDialog.open}
        onClose={() => handleDeleteDialog(false)}
        onSubmit={handleDeleteSubmit}
      />
      </div>
    </div>
  );
}

export default OrderItems;


