import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import AuraBaseDialog from 'app/main/apps/common/AuraBaseDialog';
import ImageRenderer from 'app/main/apps/common/ImageRenderer';
import ImageViewerWindow from 'app/main/dashboards/main/dialogs/ImageViewerWindow';
import pdfViewerWindow from 'app/main/dashboards/main/dialogs/pdfViewerWindow';
import { getEtchingImages } from 'app/store/auth/cartsSlice';
import Attachments from '../../shared/Attachments';

export default function Vas({ open, onClose, onSubmit, vas, editable = true, type = 'dialog', isview = false }) {
  const [values, setValues] = useState({ images: [{ id: undefined, value: '' }], text: [{ id: undefined, value: '' }] });
  const [vasInfo, setVasInfo] = useState({ text: '', images: '' })
  const images = useSelector(({ auth }) => auth.carts.images);
  const [checked, setChecked] = useState({ sandBlasting: false, etching: false });
  const dispatch = useDispatch();

  // const [reset, setReset] = useState(false);

  useEffect(() => {
    if (open) {
      if (vas) {
        setValues({ ...vas });
        setVasInfo({ text: vas?.text?.[0]?.value, images: vas?.images?.[0]?.value })
        setChecked({
          sandBlasting: Boolean(vas?.text?.[0]?.value || vas?.text?.[0]?.id),
          etching: Boolean(vas?.images?.[0]?.id || vas?.images?.[0]?.value)
        });
      } else {
        setValues({ images: [{ id: undefined, value: '' }], text: [{ id: undefined, value: '' }] });
        setVasInfo({ text: '', images: '' })
        setChecked({ sandBlasting: false, etching: false });
      }
      let customImage;
      // if (vas?.images?.[0]?.is_custom) customImage = [vas.images];
      // dispatch(getEtchingImages(customImage));
      // setReset(true);
    }
  }, [vas, open]);

  const textJSX = (vasType) => {
    const key = vasType === 'Sandblasting' ? 'text' : 'images'
    const label = vasType === 'Sandblasting' ? 'Sandblasting Info' : 'Etching Info';
    return <TextField
      label={label}
      placeholder="Name, date and quote"
      size="small"
      fullWidth
      rows={5}
      multiline
      onChange={(e) => {
        const valueStr = 'value'
        const temp = { ...values }
        if (temp[key]?.length > 0) {
          temp[key] = temp[key].map(obj => ({ ...obj, [valueStr]: e.target.value }));
        } else {
          temp[key] = [{ ...temp[key]?.[0], value: e.target.value }]
        }
        setValues(temp)
        setVasInfo((prev) => ({ ...prev, [key]: e.target.value }))
      }}
      value={values?.[key]?.[0]?.value || vasInfo[key]}
      disabled={!editable}
      InputProps={{ readOnly: isview }}
    />
  };

  const customImageJSX = (vasType, vasValues) => {
    const key = vasType === 'Sandblasting' ? 'text' : 'images'
    if (editable) {
      if (!isview)
        return (
          <div className="flex flex-col">
            <Attachments
              category="document"
              addTo="etching_images"
              allowAdd
              hideFileList
              attachmentKey={key}
              recentFiles={(file) => {
                console.log('vas file', file)
                const temp = values;
                // Check if temp[key] is an array and has elements
                if (Array.isArray(temp[key]) && temp[key].length > 0) {
                  // If the first element doesn't have an id property
                  if (!temp[key][0].id) {
                    temp[key] = [{ ...temp[key][0], ...file }];
                  } else {
                    // If the first element has an id property
                    temp[key] = [...temp[key], file];
                  }
                } else {
                  // If temp[key] is not an array or is an empty array
                  temp[key] = [file];
                }
                setValues(temp)
              }}
            />
            {
              values[key]?.[0].id && (
                <ImageRenderer
                  attachments={values[key]}
                  onClear={(ev, media) => {
                    const temp = { ...values }
                    temp[key] = temp[key]?.length === 1 ? [{ value: temp[key][0].value || null }] : temp[key]?.filter((item) => item.id !== media.id)
                    // temp[key] = [{ value: temp[key][0].value || null }]
                    setValues(temp)
                  }}
                />
              )
            }
          </div>
        )
      if (isview) {
        const param = key === 'text' ? 'Sandblasting' : 'Etching'
        if (values?.[key]?.[0]?.image_uuid)
          return imageJSX(param)

      }
    }
    return null
  }

  const imageJSX = (vasType) => {
    const key = vasType === 'Sandblasting' ? 'text' : 'images'

    // TODO can be replaced with ImageRenderer component
    return (
      <div className={clsx('flex flex-wrap', !isview && 'justify-center', isview && 'mt-14', type === 'table' && 'mt-14')}>
        {values[key]?.map((media, index) => {
          const isPDF = media['Content-Type'] === 'application/pdf'
          return (
            <div 
        key={index} 
        className="flex flex-col items-center gap-4 mx-4 my-2"
      >
              <div
                className={clsx(
                  'flex justify-center w-128 h-128 rounded-16  mb-24 overflow-hidden outline-none shadow hover:shadow-lg relative',
                  // && 'border-blue-500'
                )}
                key={index}
                onClick={() => isPDF ? pdfViewerWindow(media.data) : ImageViewerWindow([media])}
                onKeyDown={() => { }}
                role="button"
                tabIndex={0}
              >
                <img
                  src={isPDF ? 'assets/icons/pdf.png' : `data:${media['Content-Type']};base64,${media.data}`}
                  className="max-w-none w-auto h-full"
                  alt="product"
                  aria-hidden="true"
                />
              </div>
              <p className='mb-24 text-center w-96 m-auto text-sm'>
                 {media?.filename || media?.name}
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  if (type === 'table')
    return (
      <div >
        {(values.text?.[0]?.value || values.text?.[0]?.image_uuid) &&
          <>
            <Typography className='mb-10 mt-10' variant='subtitle1'>Sandblasting</Typography>
            <div className="flex items-center gap-14">
              {textJSX('Sandblasting')}
              {values.text?.[0]?.image_uuid && imageJSX('Sandblasting')}
            </div>
          </>
        }
        {(values.images?.[0]?.value || values.images?.[0]?.image_uuid) &&
          <>
            <Typography className='mb-10 mt-10' variant='subtitle1'>Etching</Typography>
            <div className="flex items-center gap-14">
              {textJSX('Etching')}
              {values.images?.[0]?.image_uuid && imageJSX('Etching')}

            </div>
          </>}
      </div>
    );

  const handleSandblasting = () => {
    setChecked((prev) => {
      if (prev.sandBlasting) {
        setValues(prevValues => ({ images: prevValues.images, text: [{ id: undefined, value: '' }] }))
        setVasInfo((prevInfo) => ({ ...prevInfo, text: '', }))
      }

      return { ...prev, sandBlasting: !prev.sandBlasting };
    });
  };

  const handleEtiching = () => {
    setChecked((prev) => {
      if (prev.etching) {
        setValues(prevValues => ({ images: [{ id: undefined, value: '' }], text: prevValues.text }))
        setVasInfo((prevInfo) => ({ ...prevInfo, images: '', }))
      }

      return { ...prev, etching: !prev.etching };
    });
  };

  const vasJSX = (options) => {
    const { defaultExpanded, heading, title1 = 'Notes', title2 = 'Upload Custom Image' } = options
    return (
      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            color="InfoText"
            variant="h6"
            className="font-semibold"
            style={{ marginTop: '-10px' }}
          >
            {heading}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ marginTop: '-30px' }} className="">
          <Typography
            color="textSecondary"
            variant="body1"
            className="font-semibold text-lg mb-5 mt-10"
          >
            {/* {heading === 'Sandblasting' ? 'Sandblasting Info' : 'Etching Info'} */}
          </Typography>
          {textJSX(heading)}

          {!isview && <Typography
            color="textSecondary"
            variant="body1"
            className="font-semibold text-lg mb-5 mt-10"
          >
            {heading === 'Sandblasting' ? 'Sandblasting Reference Document' : 'Etching Reference Document'}
          </Typography>}

          {customImageJSX(heading)}
        </AccordionDetails>
      </Accordion>
    )
  }

  const handleSubmit = () => {
    const payLoad = {
      text: values.text.map(item => ({ ...item, value: vasInfo?.text || '' })),
      images: values.images.map(item => ({ ...item, value: vasInfo?.images || '' }))
    }
    onSubmit(payLoad)
  }

  return (
    <AuraBaseDialog
      dialogProps={{ open }}
      closeDialog={onClose}
      dialogParam={{
        maxWidth: 'md',
        title: 'Value Added Services (VAS)',
        hideDialogActions: true
      }}
      disableSubmitButton={() => false}
      additionalButton={
        editable && !isview && <Button variant='contained' color='secondary' type='submit' onClick={() => handleSubmit()}        >
          {vas ? 'Update' : 'Save'}
        </Button>
      }
    // header={headerData}
    >
      <FormGroup sx={{ flexDirection: 'row' }}>
        <FormControlLabel
          control={<Checkbox checked={checked.sandBlasting} />}
          label="Sandblasting"
          onChange={handleSandblasting}
        />
        <FormControlLabel
          required
          control={<Checkbox checked={checked.etching} />}
          label="Etching"
          onChange={handleEtiching}
        />
      </FormGroup>

      {checked.sandBlasting && vasJSX({ defaultExpanded: true, heading: 'Sandblasting' })}

      {checked.etching && vasJSX({ defaultExpanded: true, heading: 'Etching' })}
    </AuraBaseDialog>
  );
}