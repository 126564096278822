import { closeDialog as fuseCloseDialog } from "app/store/fuse/dialogSlice";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useDispatch } from "react-redux";

function ConfirmAttachments(props) {
  const { title, message } = props;
  const dispatch = useDispatch();

  const onCancelClick = () => {
    dispatch(fuseCloseDialog());
    if(props.onCancel){
      props.onCancel();
    }
  };

  const onProceedClick = () => {
    dispatch(fuseCloseDialog());
    props.onProceed();
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick} color="primary">
          Cancel
        </Button>
        <Button onClick={onProceedClick} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </>
  );
}

export default ConfirmAttachments;
