import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getCustomers } from './customersSlice';
import { getCustomerInitValues } from '../customer/CustomerFormInit';
import dispatchMessage from '../../common/AuraFunctions';
import { setSalesPersonListItems } from '../../e-commerce/store/ordersSlice';

export const getCustomer = createAsyncThunk(
  'customersApp/customer/getCustomer',
  async (id, { getState }) => {
    const response = await axios.get(`/api/customers/${id}`);
    const data = await response.data.customer;
    return { data };
  }
);

export const getCustomerDetails = createAsyncThunk(
  'customersApp/customer/getCustomerDetails',
  async ({ id, params }, { dispatch, getState }) => {
    dispatch(setLoading())
    const response = await axios.get(`/api/customerDetails/${id}`, { params });
    const data = await response.data.customer;
    dispatch(resetLoading())
    return data ? getCustomerInitValues(data) : data;
  }
);

export const getSalesPersons = createAsyncThunk(
  'customersApp/customer/getSalesPersons',
  async (params, { dispatch, getState }) => {
    const response = await axios.get(`/api/getEmployees/sales`, { params });
    const data = await response.data.employees;
    dispatch(setSalesPersonListItems(data));
    return data
  }
);

export const getInvoiceTerms = createAsyncThunk(
  'customersApp/customer/getInvoiceTerms',
  async ({ params }) => {
    const response = await axios.get('/api/categoryValues', { params });
    const data = await response.data;
    return data;
  }
);

export const getSalesPersonsInfo = createAsyncThunk(
  'customersApp/customer/salesPerson',
  async (id, { dispatch, getState }) => {
    const response = await axios.get(`/api/salesPerson/${id}`);
    const data = await response.data;
    return data
  }
);

export const getCustomerUsers = createAsyncThunk(
  'customersApp/customer/getCustomerUsers',
  async (id, { dispatch, getState }) => {
    const response = await axios.get(`/api/customer/${id}?value=primary`);
    const data = response.data?.customer_users?.map((item) => ({
      ...item,
      name: item?.email,
      nameOpt: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`, // Fallback to empty string if null or undefined
    }));
    return data
  }
);

export const addCustomer = createAsyncThunk(
  'customersApp/customer/addCustomer',
  async (inputData, { dispatch, getState }) => {
    const response = await axios.post('/api/customers', inputData);
    const data = await response.data.customer;
    dispatch(getCustomers({}));
    return response.data;
  }
);

export const updateCustomer = createAsyncThunk(
  'customersApp/customer/updateCustomer',
  async (inputData, { dispatch, getState }) => {
    dispatch(setLoading())
    const response = await axios.put(`/api/customers/${inputData.id}`, inputData);
    const data = await response.data.customer;
    return data;
  }
);

export const createCustomerLogin = createAsyncThunk(
  'customersApp/customer/createCustomerLogin',
  async (id, { dispatch, getState }) => {
    const response = await axios.post(`/api/createLogin/${id}`);
    const data = await response.data;
    dispatch(getCustomers({}));
    return data;
  }
);
export const enableDisableCustomerLogin = createAsyncThunk(
  'customersApp/customer/enableDisableCustomerLogin',
  async (inputData, { dispatch, getState }) => {
    const response = await axios.post(`/api/enableDisableCustomerLogin/${inputData.id}`, inputData);
    const data = await response.data;
    dispatch(getCustomers({}));
    return data;
  }
);
export const removeCustomer = createAsyncThunk(
  'customersApp/customer/removeCustomer',
  async (customerId, { dispatch, getState }) => {
    try {
      const data = await axios.delete(`/api/customers/${customerId}`);
      dispatchMessage(dispatch, data.data.message)
      dispatch(getCustomers({}));
      return customerId;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const initState = {
  loading: false,
  data: null,
  customerDialog: {
    props: {
      open: false,
    },
    data: null,
    param: null,
  },
  shipingAddressDialog: {
    props: {
      open: false
    },
    data: null
  },
  salesPersons: null,
  invoiceTerms: null,
  customerUsers: null,
};

const customerSlice = createSlice({
  name: 'customersApp/customer',
  initialState: initState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = true;
    },
    resetLoading: (state, action) => {
      state.loading = false;
    },
    newCustomer: {
      reducer: (state, action) => {
        state.data = action.payload;
      },
      prepare: (event) => ({
        payload: getCustomerInitValues(),
      }),
    },
    resetCustomer: (state, action) => {
      state.data = null;
    },
    openCustomerDialog: (state, action) => {
      state.customerDialog = {
        props: {
          open: true,
        },
        data: action.payload.data,
        param: action.payload.param,
      };
    },
    closeCustomerDialog: (state, action) => {
      state.customerDialog = {
        props: {
          open: false,
        },
        data: null,
        param: null,
      };
    },
    openShippingAddressDialog: (state, action) => {
      state.shipingAddressDialog = {
        props: {
          open: true,
          type: action.payload.type || 'Add',
          index: action.payload.index
        },
        data: action.payload.data
      }
    },
    closeShippingAddressDialog: (state, action) => {
      state.shipingAddressDialog = {
        props: {
          open: false,
        },
        data: null
      }
    }
  },
  extraReducers: {
    [getCustomer.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [getCustomerDetails.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getSalesPersons.fulfilled]: (state, action) => {
      state.salesPersons = action.payload?.map((item) => ({ id: item.id, name: item.name }));
    },
    [getInvoiceTerms.fulfilled]: (state, action) => {
      state.invoiceTerms = action.payload?.map((item) => ({ id: item.id, name: item.name }))
    },
    [getCustomerUsers.fulfilled]: (state, action) => {
      state.customerUsers = action.payload?.map((item) => ({ id: item.id, name: item.name }));
    },
    [addCustomer.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [addCustomer.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateCustomer.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateCustomer.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  setLoading,
  resetLoading,
  newCustomer,
  resetCustomer,
  openCustomerDialog,
  closeCustomerDialog,
  openShippingAddressDialog,
  closeShippingAddressDialog
} = customerSlice.actions;

export default customerSlice.reducer;
